//@author: travis
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { S25CopyObjectComponent } from "./s25.copy.object.component";
import { S25EditableModule } from "../s25-editable/s25.editable.module";
import { S25ItemModule } from "../s25-item/s25.item.module";
import { S25LoadingInlineModule } from "../s25-loading/s25.loading.inline.module";
import { S25ContactInfoModule } from "../s25-contact-info/s25.contact.module";
import { S25PrintObjectReportComponent } from "./s25.print.object.report.component";
import { S25DocumentManagementModule } from "../system-settings/document-management/s25.document.management.module";
import { S25IconModule } from "../s25-icon/s25.icon.module";
import { S25ReportComponent } from "../s25-report/s25.report.component";

@NgModule({
    declarations: [S25CopyObjectComponent, S25PrintObjectReportComponent],
    imports: [
        CommonModule,
        FormsModule,
        S25EditableModule,
        S25ItemModule,
        S25LoadingInlineModule,
        S25ContactInfoModule,
        S25DocumentManagementModule,
        S25IconModule,
        S25ReportComponent,
    ],
    providers: [S25CopyObjectComponent, S25PrintObjectReportComponent],
    exports: [S25CopyObjectComponent, S25PrintObjectReportComponent],
})
export class S25MoreActionsModule {
    constructor() {}
}
